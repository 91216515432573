import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

import "slick-carousel/slick/slick-theme.css"
import "../assets/css/slick.css"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"

import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"

import foto3 from "../images/foto3.jpg"
import foto6 from "../images/foto6.jpg"

const FilmingIncentives = () => {
  const [height, setHeight] = useState(100)

  const boundBox = useRef(null)

  useEffect(() => {
    setHeight(boundBox.current.clientHeight)
  })

  const sliderSettings = {
    speed: 0,
    slidesToShow: 2,
    autoplay: false,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToScroll: 2,
    infinite: false,
    swipeToSlide: true,
    centerMode: false,
    focusOnSelect: false,
    adaptiveHeight: true,
    dots: true,
    mobileFirst: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  }

  const whyFilmPortugal = [
    {
      title: "City of Light",
      description:
        "Lisbon has been named the “City of Light,” Portugal is known for its great natural light. With up to 300 days of sunshine a year and low levels of precipitation, Portugal’s climate allows for shooting all year round, with average temperatures of 24ºC in the summer and 14ºC in the winter.",
    },
    {
      title: "Diverse Locations",
      description:
        "From stunning beaches, forests, medieval castles, decorated palaces, modern architecture, churches, cathedrals and monasteries, exuberant gardens, historic villages and walled cities, Portugal offers extensive diversity in its scenery. Our renowned landscape includes mountains, rocky slopes, coastlines, valleys, plains, rivers, lakes, and caves, to name a few.",
    },
    {
      title: "Accessible",
      description:
        "With a network of over 3000 miles of highways, it is possible to travel across Portugal by car in less than 8 hours. There are also six international airports (Lisbon, Porto, Faro, Funchal, São Miguel and Lajes), five international ports and a well-connected train network.",
    },
    {
      title: "EXPERIENCED PROFESSIONALS",
      description:
        "Portugal has highly qualified film professionals with extensive experience in film, TV and commercial production. Portuguese teams are accustomed to working on international productions, and most are fluent in English, although Spanish and French are also widely spoken. Local crews are non-union and are available at competitive prices.",
    },
    {
      title: "Ample Facilities",
      description:
        "Portugal has a range of studios and facilities equipped with the latest technology. The majority are located in Lisbon, Porto and Algarve, there are also projects in place to develop new areas for this purpose. The ongoing focus is to expand the infrastructure to meet the most difficult requirements at an international level.",
    },
    {
      title: "Affordable",
      description:
        "Portugal has arguably one of the lowest costs of living in Western Europe. Hotels and local accommodations are price competitive with most other countries, and our exquisite cuisine is affordable. There is also a vast network of private hospitals and clinics which accept a range of international insurance plans, economizing medical emergency care for foreigners.",
    },
    {
      title: "Co-production agreements",
      description:
        "Portugal has co-production agreements with more than 60 countries worldwide, including all countries with Portuguese as the official language, which represent +250 million Portuguese speakers (such as Brazil, Mozambique and Angola). Portugal also has agreements with countries in Latin America.",
    },
    {
      title: "Safe & Stable",
      description:
        "According to the global peace index of 2020, Portugal is the 3rd safest country in the world. This is mainly due to crime rates in Portugal being some of the lowest in Europe. Additionally, a study by InterNations conducted in 2022 listed Portugal as the “Friendliest Country in the World,” welcoming foreigners with “Braços Abertos” (open arms).",
    },
    {
      title: "Quality Health Care",
      description:
        "Portugal's quality health system has a vast public and private hospital network. The state-provided healthcare is free for all citizens and legal residents. Still, there are also several international health insurance providers and insurance companies that serve foreigners visiting Portugal.",
    },
    {
      title: "Attractive Cash Rebate",
      description:
        "The Portuguese direct tax rebate applies to Film, Audiovisual and VOD productions. With a minimum expenditure of 500k, the support ranges from 20% - 30% of the cost of production, based on eligible local spend. The rebate percentage is determined by a “Cultural Test” focusing on the characteristics of the project.",
    },
    
  ]

  return (
    <Layout>
      <Navbar title="FILMING INCENTIVES" />

      <div
        className="hidden lg:grid absolute bg-red red-cut-film-incentive"
        style={{ height }}
      ></div>
     
      <div ref={boundBox} style={{ overflow: 'hidden' }}>
        <section
          className="w-screen bg-white py-18 px-6"
          style={{ overflow: "hidden" }}
        >
          <div className="container mx-auto">
            <div className="mx-auto max-w-2xl pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24 items-center">
              <div className="col-span-2 mb-10">
                <p className="lg:max-w-4xl text-films-2 text-5xl leading-normal tracking-widest text-black text-start py-4 md:px-4 lg:px-16 lg:pr-24 ">
                  PORTUGAL IS FIRMLY SETTING ITSELF AS ONE OF THE MOST DESIRABLE
                  LOCATIONS TO FILM IN EUROPE.
                  <br />
                </p>
                <p style={{maxWidth: "40rem"}} className="number-contact-1 lg:max-w-xl max-w-xl text-2xl leading-normal text-black text-start py-4 md:px-4 lg:px-16 lg:pr-12">
                  Not only for its experienced, multi-lingual crews, beautiful
                  locations and can-do attitude...
                  <br />
                  <br />
                  It also hosts one of Europe’s most attractive film incentives
                  - offering up to 30% cash rebate on productions over 500k
                  Euros.
                  <br />
                  <br />
                  Our team at AG are experienced in guiding the process from
                  Pre-Production all the way through to Delivery, ensuring your
                  production receives the maximum amount of support from any
                  available incentives. We currently have a 100% success rate
                  when it comes to filing a rebate for our productions.
                  <br />
                </p>
              </div>
              <img
                className="md:block hidden film-incentive-img"
                style={{ maxWidth: "150%", zIndex: 1 }}
                src={foto3}
                alt="Workflow"
              />
              <img
                className="md:hidden pt-24 pb-24 film-incentive-img"
                style={{ zIndex: 1 }}
                src={foto3}
                alt="Workflow"
              />
            </div>
          </div>
        </section>
        <div
          className="block lg:hidden lg:grid bg-red red-cut-film-incentive"
        ></div>
      </div>

      <section className="w-screen overflow-x-hidden bg-white">
        <div className="container overflow-y-hidden mx-auto">
          <p className="text-aboutus-6 my-8 text-5xl font-bold leading-normal text-center">
            MAIN FEATURES OF THE INCENTIVE
          </p>
          <div className="mx-auto max-w-2xl pt-10 pb-20 md:pb-16 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-20 lg:pt-16 lg:pb-24 px-6">
            <div className="mb-8">
              <p className=" number-contact-1 text-md font-light leading-normal text-black text-start xs:px-2">
                ● Applies to Film, Audiovisual and VOD Productions: National,
                Foreign (executive/services) and/or official and de facto
                co-productions;
                <br />
                <br />● Minimum expenditure in Portugal: € 500,000.00 for
                fiction or animation projects | € 250,000.00 for documentary or
                post-production projects;
              </p>
            </div>
            <div className="mb-8 xs:px-2">
              <p className="number-contact-1 text-md font-light leading-normal text-black text-start">
                ● Support rate of 25%, which can be increased to 30%, on
                eligible expenditure. The percentage is determined by a
                ‘Cultural Test’, which is attached to the aforementioned
                Regulation, focusing on the characteristics of the project;
                <br />
                <br />● Attribution of support on a first come first served
                basis;
              </p>
            </div>
            <div>
              <p className="number-contact-1 text-md font-light leading-normal text-black text-start xs:px-2">
                ● Payments in advance and in instalments;
                <br />
                <br />
                ● Maximum incentive allocated per project is € 4m;
                <br />
                <br />● Decision to award the incentive within 20 working days.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="hidden lg:grid absolute bg-green green-cut-film-incentive"
        style={{ height }}
      ></div>
      <div ref={boundBox}>
        <section
          className="w-screen mx-auto bg-white film-incentive-img2 pb-6"
          style={{ backgroundImage: `url(${foto6})` }}
        >
          <div className=" mx-auto w-screen">

          <div className="md:hidden absolute bg-green green-cut-2"></div>
            <p className="text-aboutus-6 pt-24 text-5xl leading-normal text-white text-center" style={{ position: 'relative', zIndex: 1 }}>
              WHO CAN APPLY
            </p>
          

            <div className="mx-auto max-w-7xl lg:grid lg:max-w-8xl lg:grid-cols-2 lg:gap-x-8 lg:pt-12 px-6" style={{ position: 'relative', zIndex: 1}}>
              <p className="number-contact-1 text-md font-light leading-normal text-white text-start"  style={{ zIndex: 1}}>
                ● Companies registered in the Cinematographic and Audiovisual
                Entities Registry, a platform provided by the Institute of
                Cinema and Audiovisual (ICA), provided they have as a corporate
                purpose:
                <br />
                <br />
                a) The activity of producing films intended for projection in
                cinemas or broadcast on television or through audiovisual
                communication services on demand or other audiovisual
                communication services;
                <br />
                <br />
                b) The provision of technical services related to cinema and
                audiovisual production, including equipment rental, technical
                post-production activities, laboratory activities for film
                production, special laboratory activities for animated films and
                sound post-production activities.
                <br />
                <br />
              </p>
              <p className="z-10 number-contact-1 text-md font-light leading-normal text-white text-start">
                ● The Registration of Cinematographic and Audiovisual Entities
                ensures the mobility of the registration of a European company,
                non-resident and without a branch in Portugal, in order to allow
                the application for the Incentive to entities with headquarters
                in another member state of the European Union or in a State of
                the European Economic Area. However, before the start date for
                the realisation of eligible expenses, the applicant entity must
                establish a company or branch with a legal personality in
                Portugal, and companies with a specific object and limited
                duration to the production and management of one or more works –
                ‘special purpose vehicle companies’ – are permitted.
                <br />
                <br />● If the project is a co-production that involves more
                than one producer established in Portugal, each of the
                co-producers must make an application for the Incentive,
                regarding the eligible expenses they support.
              </p>
            </div>
          </div>
        </section>
      </div>

      <section className="w-screen bg-white overflow-x-hidden">
        <div className="lg:grid lg:grid-cols-1 mx-auto md:mx-32">
          <p className="text-aboutus-6 max-w-3xl mx-auto pt-20 text-4xl md:text-3xl leading-normal tracking-widest text-center text-black">
            WHY FILM IN PORTUGAL
          </p>

          <div className="films-image-slider">
            <div className="hidden md:grid absolute my-20 right-0 bg-blue blue-cut-filming"></div>
            
            <Slider {...sliderSettings}>
              {whyFilmPortugal.map((filmPortugal, i) => (
                <div
                  className={`mx-auto py-16 px-6 md:pl-24 fix-slide-film-inc`}
                >
                  <div className="lg:px-12 md:px-5">
                    <pw className="highlight-number-slider">
                      {i + 1}
                    </pw>
                    <p className="slider-title slider-title-film-inc max-w-md text-md font-light leading-normal text-black text-center uppercase">
                      {filmPortugal.title}
                    </p>
                    <p className="number-contact-1 max-w-md text-md font-light leading-normal text-black text-center pt-8">
                      {filmPortugal.description}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <p className="text-aboutus-6 max-w-md mx-auto pt-32 pb-16 text-lg md:text-3xl leading-normal tracking-widest text-center text-black">
          FILL THE FORM BELOW FOR MORE INFORMATION.
        </p>
      </section>

      <ContactForm />
      <Footer />
    </Layout>
  )
}

export default FilmingIncentives
